import React, {useContext, useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import '../../utils/fix-map-icon';
import {SearchContext} from "../../contexts/search.context";
import {SimpleAdEntity} from 'types';
import {SingleAd} from "./SingleAd";
import {apiUrl} from "../config/api";

import "./Map.css"
import'leaflet/dist/leaflet.css';

export const Map = ()=> {
  const {search} = useContext(SearchContext);
  const [ads, setAds] = useState<SimpleAdEntity[]>([])

  useEffect(()=> {
    (async ()=> {
      const res = await fetch(`${apiUrl}/ad/search/${search}`);
      const data = await res.json();
      setAds(data);
    })()
  }, [search])

  return (
    <div className="map">
      <MapContainer center={[51.742349, 19.284055]} zoom={7} >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="https://www.openstreetmap.org/copyright"
        />
        {
          ads.map(ad => (
            <Marker key={ad.id} position={[ad.lat, ad.lon]}>
              <Popup>
                <SingleAd id={ad.id}/>
              </Popup>
            </Marker>
          ))
        }
      </MapContainer>
    </div>
  )
}
